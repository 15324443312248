<template>
    <div>
        <img class="example-headImg" src="../../assets/image/technicalService/04-3-1banner.png"/>
        <div class="head-explain">
            <div class="head-explain-title">定制开发及运维</div>
            <div class="head-explain-content">凭精湛的研发技术，成熟的项目实施方法论以及快速交付平台，</div>
            <div class="head-explain-content" style="margin-top:-3rem">为客户提供各类业务系统的定制化开发服务。</div>
        </div>
        <div class="container-1">
            <div class="box-mm" style="margin-top:6rem;width: 139rem;">
                <div class="box-content">
                    <img class="box-content-img" src="../../assets/image/technicalService/04-3-1小图.png"/>
                </div>
                <div class="box-content">
                    <div class="box-content-content" style="margin-top:7rem">
                        从开发到优化，从管理到运维，我们的服务涵盖企业应用生命周期的每个阶段。通过新一代IT技术，帮助客户实现业务流程的数字化转型。
                        无论重塑陈旧系统，还是0-1应用开发，我们都以“精益”为原则，助企业敏捷转型。
                        <br/><br/>
                        我们利用DevOps持续集成/部署的方法，将研发运维一体化。从传统的被动发现到主动预警，采取及时的应对措施和容灾方案，实现运维工作自动化与智能化。</div>
                </div>
            </div>
        </div>
        <div class="custom-head-list">
            <div class="box">
                <div class="custom-list-item custom-list-ischeck" @click="goAssignBlock('block'+ 0,50)"><span class="title">技术能力</span></div>
                <div class="custom-list-item" @click="goAssignBlock('block'+ 1,50)"><span class="title">项目实施方法论</span></div>
                <div class="custom-list-item" @click="goAssignBlock('block'+ 2,50)"><span class="title">快速交付平台</span></div>  
            </div>  
        </div>
        <div class="custon-title1">技术能力</div>
        <div class="container-2" style="display:flex;height: 64rem;" ref="block0">
            <img class="custom-img1" src="../../assets/image/technicalService/04-3-1技术能力.png"/>
        </div>

        <div class="container-1" ref="block1">
            <div class="custom-box1">
                <div class="title1">项目实施方法论</div>
                <div class="title2">在开发早期，识别潜在的问题和风险；在开发过程中，从平面到立体的不同层面与用户确认需求，采用小周期快速迭代，持续改进的方法保
                    证项目质量与进度。</div>
                <img class="img1" src="../../assets/image/technicalService/04-3-1实施方法论.svg"/>
                <div class="box1">
                    <div class="item-1"><div class="button-1"></div><div class="content-1"><div class="title">产出需求说明书并签字确认</div></div></div>
                    <div class="item-1"><div class="button-1"></div><div class="content-1"><div class="title">包括功能设计，流程设计，权限设计，视觉设计，技术设计等</div></div></div>
                    <div class="item-1"><div class="button-1"></div><div class="content-1"><div class="title">程序开发，单元测试，迭代式系统演示</div></div></div>
                    <div class="item-1"><div class="button-1"></div><div class="content-1"><div class="title">测试用例，修复完善</div></div></div>
                    <div class="item-1"><div class="button-1"></div><div class="content-1"><div class="title">针对提出的必要的优化建议，微调系统进行完善。经过最后的打磨和多轮测试交付给UAT。</div></div></div>
                    <div class="item-1"><div class="button-1"></div><div class="content-1"><div class="title">产出操作手册，培训录屏及其他支持材料</div></div></div>
                    <div class="item-1"><div class="button-1"></div><div class="content-1"><div class="title">测试验收</div></div></div>
                    <div class="item-1"><div class="button-1"></div><div class="content-1"><div class="title">期初数据收集和迁移，人员/角色/权限的配置，系统参数配置等上线准备工作</div></div></div>
                    <div class="item-1"><div class="button-1"></div><div class="content-1"><div class="title">收集上线后的问题，修复缺陷</div></div></div>
                </div>
                <div class="box2">
                    <div class="box2-left">
                        <div class="content-1"><div class="title1-1">项目管理</div></div>
                        <div class="content-2 " style="margin-top:0.5rem" @click="checkIndex=1"><div :class="{'title1-1':true,'ischecked':checkIndex==1} ">卓越运维</div></div>
                        <div class="content-2 " @click="checkIndex=2"><div  :class="{'title1-1':true,'ischecked':checkIndex==2} ">基于系统的持续运维</div></div>
                        <div class="content-2" @click="checkIndex=3"><div :class="{'title1-1':true,'ischecked':checkIndex==3} ">服务模型</div></div>
                    </div>
                    <div class="box2-right">
                        <div v-if="checkIndex===1">
                            <div class="content">对于建设0-1的项目，早期阶段在需求和技术等方面会存在诸多不确定性。对于项目来说，其最大的浪费是交付没用（不能解决用户问题或带来业务成功）的
                            内容。那么我们把价值的探索和发现融入项目的交付过程中。通过以下四个阶段，逐步收敛双方在需求认识上的差异，了解真正的业务需求。实现在巩固共识的前提下，再一同前
                            行的目标。</div>
                            <div class="box2-1">
                                <div class="big-card">
                                    <div class="card">
                                        <img src="../../assets/image/technicalService/04-3-1需求调研.svg"/>
                                        <div class="title2-1">需求调研  原型设计</div>
                                        <div class="title2-2">通过封闭式3天集中沟通，头脑风暴，形成图文并茂的需求文档。</div>
                                    </div>
                                </div>
                                <div class="big-card">
                                    <div class="card">
                                        <img src="../../assets/image/technicalService/04-3-1蓝图设计.svg"/>
                                        <div class="title2-1">蓝图设计</div>
                                        <div class="title2-2">蓝图设计包括功能设计,流程设计,权限设计，视觉设计，技术设计等。在需求关键点上持续沟通和确认。</div>
                                    </div>
                                </div>
                                <div class="big-card">
                                    <div class="card">
                                        <img src="../../assets/image/technicalService/04-3-1系统演示.svg"/>
                                        <div class="title2-1">系统演示</div>
                                        <div class="title2-2">在每个迭代开发结束后,通过操作演示,从平面上升到立体层面，与用户沟通和再次确认需求。</div>
                                    </div>
                                </div>
                                <div class="big-card">
                                    <div class="card">
                                    <img src="../../assets/image/technicalService/04-3-1功能确认.svg"/>
                                        <div class="title2-1">最终功能确认</div>
                                        <div class="title2-2">在这个阶段，通常不再收集新的需求，重点在于查漏补缺。通过关键用户上手操作体验。对项目范围内所有功能进行最后一次确认和固化。针对提出的必要的优化建议，微调系统进行完善。经过最后的打磨和多轮测试后交付给UAT。经过这个阶段后需求完全冻结。</div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div v-if="checkIndex===2">
                            <div class="content">对于1-N的项目，我们也逐步迎来了一场以“卓越运维”为目标的变革，团队的职责不再局限于系统的质保和维护，而是合并到各业务及应用的
                                开发团队中去，全面践行DevOps的思想。通过Amazon云及一系列智能化工具和手段，进一步提升运维效率和稳定性。</div>
                            <div class="box2-2">
                                <div class="card">
                                    <img src="../../assets/image/technicalService/04-3-2运维效率.svg"/>
                                    <div class="title2-1">运维效率</div>
                                    <div class="title2-2">通过有效地监控，应对各类突发故障。事前提前采取有效措施；事中提升响应速度；事后反馈，
                                        总结和优化，保障企业业务的稳定运行。</div>
                                </div>
                                <div class="card">
                                    <img src="../../assets/image/technicalService/04-3-2运维安全.svg"/>
                                    <div class="title2-1">运维安全</div>
                                    <div class="title2-2">运维安全事件（系统宕机、数据异常、信息丢失等）可能给企业带来致命的打击，甚至关乎业务
                                        的生死存亡。因此，防范和杜绝高危运维故障是我们一直不懈追求的目标。</div>
                                </div>
                                <div class="card">
                                    <img src="../../assets/image/technicalService/04-3-2业务持续性.svg"/>
                                    <div class="title2-1">业务持续性</div>
                                    <div class="title2-2">客户业务发展瞬息万变，特别是重要业务，迭代变更的速度非常快。在Amazon云技术的支撑下，
                                        我们有能力持续高效地对应用进行发布、部署、变配等运维变更，保障业务的连续性和快速迭代。</div>
                                </div>
                            </div>
                        </div>
                        <div v-if="checkIndex===3">
                            <div class="title1" style="margin-top:0;height: 2rem;">卓越运维服务价值</div>
                            <img class="img2-3" src="../../assets/image/technicalService/04-3-4卓越运维.svg"/>
                            <div class="title1" style="margin-top:0;height: 2rem;">系统运维</div>
                            <img class="img2-3-2" src="../../assets/image/technicalService/04-3-4系统运维.png"/>
                            <div class="title1" style="margin-top:0;height: 2rem;">运维门户平台</div>
                            <div class="box2-3">
                                <div class="card">
                                    <img src="../../assets/image/technicalService/04-3-4跟踪记录.svg"/>
                                    <div class="title3-1">跟踪记录</div>
                                    <div class="message"><div class="title3-2">跟踪管理即对问题的整个生命周期进行记录和管理.</div></div>
                                </div>
                                <div class="card">
                                    <img src="../../assets/image/technicalService/04-3-4运维管理.svg"/>
                                    <div class="title3-1">运维管理</div>
                                    <div class="message"><div class="title3-2">支持运维管理致捷或传统的开发项目。</div></div>
                                </div>
                                <div class="card">
                                    <img src="../../assets/image/technicalService/04-3-4协同合作.svg"/>
                                    <div class="title3-1">协同合作</div>
                                    <div class="message"><div class="title3-2">支持各个团那队之间的协同合作,保证各个支持环节的透明性。</div></div>
                                </div>
                                <div class="card">
                                    <img src="../../assets/image/technicalService/04-3-4数据分析.svg"/>
                                    <div class="title3-1">数据分析</div>
                                    <div class="message"><div class="title3-2">结合数据分析工具，做到对运维工作中的难点痛点了如指掌。</div></div>
                                </div>
                                <div class="card">
                                    <img src="../../assets/image/technicalService/04-3-4管理面板.svg"/>
                                    <div class="title3-1">管理面板</div>
                                    <div class="message"><div class="title3-2">提供管理面板，为持续不断优化管理运维工作捉供数据支撑。</div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-2" style="display:flex;height: 68rem;" ref="block2">
            <div class="custom-box1">
                <div class="title1">快速交付平台</div>
                <div class="title2" style="color: #333333;height: 16rem;">大型传统企业往往已完成企业信息化建设，但是对于新模式、新业务的探索仍需要大量的信息技术
                    支持，而这些是大部分现有信息化系统无法提供的。SaaS服务，可定制化程度太低，无法满足企业创新业务。而定制开发，则试错成本太高。<br/>
                    我们的快速交付平台旨在帮助大型传统企业克服数字化转型过程中面临的各种挑战，可以简单，快速，高效地开发出企业级的应用系统。</div>
                <div class="box3">
                    <div class="card">
                        <img src="../../assets/image/technicalService/04-3-1简单.svg"/>
                        <div class="title3-1">简单</div>
                        <div class="title3-2">省却编程中大量重复机械代码，让开发更多关注业务逻辑，通过代码生成，结合手动调整，实现半智能开发</div>
                    </div>
                    <div class="card">
                        <img src="../../assets/image/technicalService/04-3-1快速.svg"/>
                        <div class="title3-1">快速</div>
                        <div class="title3-2">生成统一规范的界面及数据模型，可节省大量的工作量，快速提高开发效率</div>
                    </div>
                    <div class="card">
                        <img src="../../assets/image/technicalService/04-3-1高效.svg"/>
                        <div class="title3-1">高效</div>
                        <div class="title3-2">统一规范、统一设计思路，快速开发出高效高质量代码，缩短项目开发周期</div>
                    </div>
                </div>
                <div class="custom-more" @click="jumpRout('/fast-delivery','4')"><span>了解详情</span></div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'BizfocusPagesPurchaseCollaboration',
    metaInfo:{
        meta:[
        { name:'keywords', content:'定制开发及运维,数字化转型,DevOps持续集成/部署,主动预警,运维工作自动化,运维工作智能化,卓越运维,基于系统的持续运维,服务模型,运维效率,运维安全,业务持续性' },
        { name:'description', content:'从开发到优化，从管理到运维，我们的服务涵盖企业应用生命周期的每个阶段。通过新一代IT技术，帮助客户实现业务流程的数字化转型。 无论重塑陈旧系统，还是0-1应用开发，我们都以“精益”为原则，助企业敏捷转型。我们利用DevOps持续集成/部署的方法，将研发运维一体化。从传统的被动发现到主动预警，采取及时的应对措施和容灾方案，实现运维工作自动化与智能化。' }
        ]
    },
    data() {
        return {
            checkIndex:1,
            activeIndex:'4',
            navOffsetTop: 0,
        };
    },

    mounted() {
        this.activeIndex = '4'
        this.newsIndex = false
        this.refreshIndexInfo({
            data:this.activeIndex ,
            news:this.newsIndex
        });
        // 监听滚动事件
        window.addEventListener('scroll', this.fiexdNav)
        this.getData()
    },
    destroyed () {
        // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
        window.removeEventListener('scroll', this.fiexdNav)
    },
    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),
        jumpRout(route,index){
            this.activeIndex = index
            localStorage.setItem('headId',index);
            this.$router.push(route)
        },
        goAssignBlock(el, speed) {
            const navHeight = document.querySelector('.custom-head-list').offsetHeight
            let _this = this;
            let windowH = window.innerHeight; //浏览器窗口高度
            let h = this.$refs[el].offsetHeight; //模块内容高度
            let t = this.$refs[el].offsetTop; //模块相对于内容顶部的距离
            let top = t - (windowH - h) / 2; //需要滚动到的位置，若改为 t 则滚动到模块顶部位置，此处是滚动到模块相对于窗口垂直居中的位置
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop; //滚动条距离顶部高度
            let currentTop = scrollTop; //默认滚动位置为当前滚动条位置，若改为0，则每次都会从顶部滚动到指定位置
            let requestId;
            //采用requestAnimationFrame，平滑动画
            function step() {
                //判断让滚动条向上滚还是向下滚
                if (scrollTop < top) {
                if (currentTop <= top) {
                    //   window.scrollTo(x,y) y为上下滚动位置
                    window.scrollTo(0, currentTop - navHeight -100);
                    requestId = window.requestAnimationFrame(step);
                } else {
                    window.cancelAnimationFrame(requestId);
                }
                //向下滚动
                currentTop += speed;
                } else {
                if (top <= currentTop) {
                    //注：此处 - speed 是解决居中时存在的问题，可自行设置或去掉
                    window.scrollTo(0, currentTop - speed - navHeight);
                    requestId = window.requestAnimationFrame(step);
                } else {
                    window.cancelAnimationFrame(requestId);
                }
                //向上滚动
                currentTop -= speed;
                }
            }
            window.requestAnimationFrame(step);
        },
        /** 设置导航条nav到达页面顶部时固定 **/
        // 1.获取导航条nav的offsetTop值，存储在data中（注：之所以不放在滚动事件中，是为了以防添加固定样式后offsetTop值为零,导致页面需要滚动到最上面才可以回到原位）
        getData () {
            this.navOffsetTop = document.querySelector('.custom-head-list').offsetTop + 60
            console.log('this.navOffsetTop',this.navOffsetTop)
        },
        fiexdNav () {
            // 2.获取当前页面的卷曲高度
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            const nav = document.querySelector('.custom-head-list')
            // 3.判断卷曲高度是否大于等于导航条的offsetTop值
            if (scrollTop > this.navOffsetTop) {
                //   3.1若满足，则给nav导航添加固定样式
                nav.classList.add('fixedNav')
            } else {
                //   3.2若不满足，则删除nav导航的固定样式
                nav.classList.remove('fixedNav')
            }

            /** 当滚动到一定区域时给导航项添加选中样式 **/
            //  1.获取所有锚点元素
            let contents = document.querySelectorAll('.container-1>div')
            // contents = this.$refs[el]
            console.log('1111',this.$refs['block0'].offsetTop)
            // 2.获取锚点元素的offsetTop值，并收集在一个数组
            const contentsOffsetTop = []
            // contents.forEach(item => {
            //     contentsOffsetTop.push(item.offsetTop)
            //     console.log('2222',item.offsetTop)
            // })
            contentsOffsetTop.push(this.$refs['block0'].offsetTop)
            contentsOffsetTop.push(this.$refs['block1'].offsetTop)
            contentsOffsetTop.push(this.$refs['block2'].offsetTop)
            // 3.获取页面高度
            const pageHeight = window.innerHeight
            // 4.获取nav的子元素
            const navChildren = document.querySelectorAll('.custom-head-list .custom-list-item')
            // 5.遍历锚点元素的offsetTop值
            for (let i = 0; i < contentsOffsetTop.length; i++) {
                // 5.1 设置第一项导航默认为选中状态
                if (i === 0) {
                navChildren[0].classList.add('custom-list-ischeck')
                } else if (scrollTop > contentsOffsetTop[i - 1] + pageHeight / 3) {
                // 排他思想
                for (let j = 0; j < contentsOffsetTop.length; j++) {
                    navChildren[j].classList.remove('custom-list-ischeck')
                    navChildren[i].classList.add('custom-list-ischeck')
                }
                } else {
                navChildren[i].classList.remove('custom-list-ischeck')
                }
            }
        },
        /**
         *设置点击导航跳转到指定选择器对应的锚点元素
        * @param {*} selector
        **/
        skipTo (selector) {
            const navHeight = document.querySelector('.custom-head-list').offsetHeight
            // scrollIntoView() js原生方法，实现锚点滚动过渡
            const target = document.querySelector(selector)
            target.scrollIntoView({ behavior: 'smooth' })
            // scrollTo() 把内容滚动到指定的坐标。减去导航高度的目的：导航用定位固定在顶部，如果不减去，导航栏会遮挡一部分内容
            window.scrollTo(0, target.offsetTop - navHeight)
        }
    },
};
</script>

<style lang="less" scoped>
.custom-head-list{
    width: 190rem;
    border-bottom: 2px solid #F8F8F8;
    height: 7.9rem;
    display: flex;
    .box{
        width: 139rem;
        margin: auto;
        display: flex;
        transition: all 0.5s;
    }
}
.custom-list-item{
    width: 20rem;
    height: 8rem;
    display: flex;
    text-align: center;
    align-items: center;
    cursor: pointer;
    .title{
        width: 100%;
        font-size: 1.8rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #4F4F4F;
        line-height: 2rem;
        text-align: center;
    }
}
.custom-list-item:hover{
    border-bottom: 2px solid #11A84F;
    color: #11A84F;
}
.custom-list-ischeck{
    border-bottom: 2px solid #11A84F;
    color: #11A84F;
}
.custon-title1{
    width: 139rem;
    text-align: justify ;
    margin:6rem 0 1.7rem  26.1rem;
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.custom-img1{
    width: 139.5rem;
    height: 52rem;
    margin: auto;
    margin-top: 6rem;
}
.custom-box1{
    margin-top:6rem;
    width: 139rem;
    margin: auto;
    .title1{
        height: 2rem;
        font-size: 2rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #000000;
        line-height: 3rem;
        text-align: justify ;
        margin-top: 6rem;
    }
    .title2{
        width: 139.5rem;
        height: 4.2rem;
        font-size: 1.6rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #666666;
        line-height: 3rem;
        text-align: justify;
        margin-top: 3.1rem;
    }
    .img1{
        width: 139.8rem;
        height: 7rem;
        margin-top: 4rem;
    }
    .box1{
        width: 139.8rem;
        display: flex;
        height: 22rem;
        margin-top: -7rem;
        .item-1{
            width: 15.4rem;
            height: 7rem; //7
            overflow: hidden;
        }
        .item-1:hover{
            height: 22rem;
            transition: all .3s;
        }
        .button-1{
            width: 5rem;
            height: 7rem;
            display: flex;
            margin: auto;
            cursor: pointer;
        }
        .content-1{
            width: 15.4rem;
            height: 13rem;
            background: #F9F9F9;
            margin-top: 1rem;
            display: flex;
            text-align: center;
            align-items: center;
            .title{
                max-width: 13.4rem;
                // height: 6.1rem;
                font-size: 1.6rem;
                font-family: 'CN_Normal';
                font-weight: 400;
                color: #666666;
                line-height: 2.4rem;
                margin: auto;
                text-align: center;
            }
        }
    }
    .box2{
        width: 139.8rem;
        // height: 45rem;
        display: flex;
        margin-top: 6rem;
        .box2-left{
            width: 32rem;
            .content-1{
                width: 32rem;
                height: 6rem;
                // border: 1px solid #E6E6E6;
                .title1-1{
                    font-size: 1.6rem;
                    font-family: 'CN_Medium';
                    font-weight: 500;
                    color: #000000;
                    line-height: 2.4rem;
                    // color: #11A84F;
                    text-align: center;

                    margin: 1.8rem 0 0 0rem;
                }
            }
            .content-2{
                width: 32rem;
                height: 6rem;
                border: 1px solid #E6E6E6;
                cursor: pointer;
                .title1-1{
                    font-size: 1.6rem;
                    font-family: 'CN_Regular';
                    font-weight: 400;
                    color: #000000;
                    line-height: 2.4rem;
                    text-align: center;

                    margin: 1.8rem 0 0 0rem;
                }
                .ischecked{
                    font-family: 'CN_Medium';
                    font-weight: 500;
                    color: #11A84F;
                }
            }
            .content-2:hover{
                background: #F9F9F9;
            }
        }
        .box2-right{
            width: 101.6rem;
            margin-left: 6rem;
            .content{
                width: 101.6rem;
                height: 12rem;
                font-size: 1.6rem;
                font-family: 'CN_Normal';
                font-weight: 400;
                color: #333333;
                line-height: 3rem;
                text-align: center;
            }
            .box2-1{
                width: 101.6rem;
                height: 25rem;
                margin-bottom: 4.5rem;
                /*设置当前元素为flex模式*/
                display: flex;
                /*行元素默认不折行，设置为折行*/
                flex-wrap: wrap;
                justify-content: space-between;
                .big-card{
                   .card{
                        width: 24.6rem;
                        height: 20rem;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 12px 0px rgba(233,233,233,0.6);
                        overflow: hidden;
                        transition: all 1s;
                        img{
                            // margin: auto;
                            margin-top: 4rem;
                            width: 6.4rem;
                            height: 6.4rem;
                        }
                        .title2-1{
                            height: 2rem;
                            font-size: 2rem;
                            font-family: 'CN_Medium';
                            font-weight: 500;
                            color: #000000;
                            line-height: 3rem;
                            margin: auto;

                            margin: 3rem 0 0 0;
                        }
                        .title2-2{
                            width: 20.4rem;
                            height: 0rem;
                            font-size: 1.6rem;
                            font-family: 'CN_Regular';
                            font-weight: 400;
                            color: #4F4F4F;
                            line-height: 3rem;

                            overflow: auto;

                            margin: auto;
                            margin-top: 1.5rem;
                            text-align: justify ;
                            padding-left: 1rem;
                            padding-right: 0.2rem;
                        }
                    } 
                }
                .big-card:hover>.card img{
                    display: none;
                }
                .big-card:hover>.card .title2-1{
                    display: none;
                }
                .big-card:hover>.card{
                    // height: 25rem;
                    transform: rotateY(180deg) scaleX(-1);
                    transition: all 1s;
                }
                .big-card:hover>.card .title2-2{
                    height:20rem;
                    padding-bottom: 3rem;
                    overflow: auto;
                    transition: all 1s;
                    &::-webkit-scrollbar {
                        width: 0.2rem;
                        height: 0.1rem;
                        }

                        &::-webkit-scrollbar-thumb {
                        border-radius: 0.1rem;
                        -webkit-box-shadow: inset 0 0 0.5rem #e9e9e9;
                        background: #e9e9e9;
                        }

                        &::-webkit-scrollbar-track {
                        //-webkit-box-shadow: 0;
                        border-radius: 0;
                        background: #ffffff;
                        }
                }

            }
            .box2-2{
                width: 101.6rem;
                height: 36rem;
                margin-bottom: 6rem;

                /*设置当前元素为flex模式*/
                display: flex;
                /*行元素默认不折行，设置为折行*/
                flex-wrap: wrap;
                justify-content: space-between;
                .card{
                    width: 33rem;
                    height: 36rem;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 12px 0px rgba(233,233,233,0.6);
                    img{
                        // margin: auto;
                        margin-top: 4rem;
                    }
                    .title2-1{
                        height: 2rem;
                        font-size: 2rem;
                        font-family: 'CN_Medium';
                        font-weight: 500;
                        color: #000000;
                        line-height: 3rem;

                        margin: 3rem 0 0 0;
                    }
                    .title2-2{
                        width: 28.9rem;
                        font-size: 1.6rem;
                        font-family: 'CN_Regular';
                        font-weight: 400;
                        color: #4F4F4F;
                        line-height: 3rem;
                        overflow: hidden;

                        margin: auto;
                        margin-top: 1.5rem;
                        text-align: justify ;
                    }
                }
            }
            .img2-3{
                width: 103.8rem;
                height: 23.7rem;
                margin-top: 3.5rem;
                margin-bottom: 4.8rem;
            }
            .img2-3-2{
                width: 101.6rem;
                height: 21.9rem;
                margin-top: 3.5rem;
                margin-bottom: 6rem;
            }
            .box2-3{
                width: 101.6rem;
                height: 30rem;
                margin-bottom: 6rem;
                margin-top: 4rem;

                /*设置当前元素为flex模式*/
                display: flex;
                /*行元素默认不折行，设置为折行*/
                flex-wrap: wrap;
                justify-content: space-around;
                .card{
                    width: 20rem;
                    height: 13rem;
                    background: #FFFFFF;
                    overflow: hidden;
                    img{
                        width: 10rem;
                        height: 10rem;
                        cursor: pointer;
                    }
                    .title3-1{
                        height: 2.4rem;
                        font-size: 1.6rem;
                        font-family: 'CN_Regular';
                        font-weight: 400;
                        color: #333333;
                        line-height: 2.4rem;
                        cursor: pointer;

                        margin-top: 1rem;
                    }
                    .message{
                        width: 20rem;
                        height: 14rem;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 12px 0px rgba(233,233,233,0.6);
                        display: flex;
                        align-items: center;

                        margin-top:0.5rem;
                        .title3-2{
                            width: 16.9rem;
                            height: 10.2rem;
                            font-size: 1.6rem;
                            font-family: 'CN_Regular';
                            font-weight: 400;
                            color: #4F4F4F;
                            line-height: 3rem;
                            text-align: justify ;

                            margin: auto;
                        }
                    }
                }
                .card:hover{
                    height: 29.1rem;
                    transition: all .5s;
                }
            }
        }
    }
    .box3{
        width: 139.3rem;
        /*设置当前元素为flex模式*/
        display: flex;
        /*行元素默认不折行，设置为折行*/
        flex-wrap: wrap;
        justify-content: space-between;

        margin-top: -2rem;
        .card{
            width: 45.6rem;
            height: 26.7rem;
            background: #FFFFFF;
            box-shadow: 0px 0px 12px 0px rgba(233,233,233,0.6);
            img{
                margin-top: 4rem;
                width: 6.4rem;
                height: 6.4rem;
            }
            .title3-1{
                height: 2rem;
                font-size: 2rem;
                font-family: 'CN_Medium';
                font-weight: 500;
                color: #000000;
                line-height: 3rem;

                margin-top: 2rem;
            }
            .title3-2{
                width: 39.6rem;
                height: 7.5rem;
                font-size: 1.6rem;
                font-family: 'CN_Regular';
                font-weight: 400;
                color: #4F4F4F;
                line-height: 3rem;
                text-align: center;
                margin: auto;

                margin-top: 1.5rem;
            }
        }
    }
    .custom-more{
        width: 14rem;
        height: 4.6rem;
        border: 1px solid #11A84F;
        margin: auto;
        display: flex;
        text-align: center;
        align-items: center;
        cursor: pointer;

        margin-top: 4.5rem;
        span{
            // width: 6.4rem;
            height: 3rem;
            font-size: 1.6rem;
            font-family: 'CN_Normal';
            font-weight: 400;
            color: #11A84F;
            line-height: 3rem;
            margin: auto;
        }
    }
}
</style>
